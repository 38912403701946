@font-face {
  font-family: Apercu;
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.eot);
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.woff2)
      format('woff2'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.woff)
      format('woff'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.ttf)
      format('truetype'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.svg#apercu-regular)
      format('svg'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-regular.eot#iefix)
      format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.eot);
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.woff2)
      format('woff2'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.woff)
      format('woff'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.ttf)
      format('truetype'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.svg#apercu-medium)
      format('svg'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-medium.eot#iefix)
      format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.eot);
  src: url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.woff2)
      format('woff2'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.woff)
      format('woff'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.ttf)
      format('truetype'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.svg#apercu-bold)
      format('svg'),
    url(https://media.pathway-dev.cfadevelop.com/fonts/apercu-bold.eot#iefix)
      format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

html,
body,
#root {
  font-family: Apercu, sans-serif;
  font-weight: 400;
}

body,
a,
a:hover {
  color: #485259;
}

a {
  text-decoration: none;
}

textarea:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus {
  outline: none;
  box-shadow: none !important;
}

.error-w-icon {
  font-size: 12px;
  color: #dd0031;
  background-image: url(../icons/UI/Exclamation.png);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 0 50%;
  padding: 10px 0 10px 23px;
}

a.primary-menu {
  color: #485259;
}

a.primary-menu.sel,
a.primary-menu:hover {
  background: #ceebf3;
  color: #004f71;
}

a.primary-menu.sel svg path,
a.primary-menu:hover svg path {
  stroke: #004f71;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: visible !important;
}

/* Smart Banner */
.smartbanner-container {
  background: #f5f5f5;
}

.smartbanner-title {
  color: #000000 !important;
  text-shadow: none;
}

.smartbanner-description {
  color: #848489;
  text-shadow: none;
}

.smartbanner-button {
  box-shadow: none !important;
}

.smartbanner-button-text {
  color: #ffffff;
  background: #3478f6 !important;
  border-radius: 999px;
  padding: 8px !important;
}

.smartbanner-close {
  background: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
  color: #bbbbc0 !important;
}

.smartbanner-top {
  position: inherit !important;
  z-index: 3 !important;
}

html {
  margin-top: 0 !important;
}

/* pevents blank page at end of pdf prints */
@media print {
  html,
  body {
    border: 1px solid white !important;
    height: 99% !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
}

/* sticky footer */
.cfa-side-navigation-page-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.cfa-side-navigation-wrapper {
  height: 100%;

}
html, body, #root {
  height: 100%;
}
/* end sticky footer */

