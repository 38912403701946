/* override bootstrap*/
.accordion-header > *,
.card-body {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.list-results .card-body, .list-results-no-pad .card-body {
    font-size: 16px;
    font-weight: 400;
    padding: 15px !important;
}

.list-results-no-pad .card-body {
    padding: 0 !important;
}

.card,
.accordion-item {
    cursor: pointer;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 7px;
    border: 0;
    box-shadow: 0 1px 8px -1px rgb(142 150 156 / 20%);
    width: 100%;
}

.card {
    justify-content: center;
}

.accordion-item {
    margin: 0 0 10px 0;
    border: 0 !important;
    border-radius: 7px !important;
    padding: 2px;
    box-shadow: 0 1px 8px -1px rgb(142 150 156 / 20%);
}

.accordion-button {
    padding: 18px;
}

.accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: inherit;
}

.card-body {
    display: flex;
    align-items: center;
    padding: 13px 15px !important;
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #1E272F;
}
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #1E272F;
}
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #1E272F;
}
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #1E272F;
}

hr {
    opacity: 1;
    border: 1px solid transparent;
    border-top: 1px solid transparent;
  }